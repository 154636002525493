import { useRecoilState } from 'recoil';
import { workstationTypeState } from '@atoms/workstationTypeState';
import { useEffect } from 'react';
import { WorkstationType } from '@constants/WorkstationType';


export function useWorkstationType() {
  const [workstationType, setWorkstationTypeState] = useRecoilState(workstationTypeState);

  useEffect(() => {
    const savedType = localStorage.getItem('workstation-type');

    if (!savedType) {
      localStorage.setItem('workstation-type', WorkstationType.Admin);

      setWorkstationTypeState(WorkstationType.Admin);
    }

    setWorkstationTypeState(savedType as WorkstationType);
  });

  const setWorkstationType = (type: WorkstationType) => {
    localStorage.setItem('workstation-type', type);
    setWorkstationTypeState(type);
  };

  return { workstationType, setWorkstationType };
}
