import { Col } from 'antd';
import { ReferrersTable } from './ReferrersTable';

export function ReferrersTab() {
  return (
    <Col>
      <ReferrersTable />
    </Col>
  );
}
