import axios from 'axios';
import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { isLoggedInState } from '@atoms/isLoggedInState';
import { accessTokenState } from '@atoms/accessTokenState';


export function useLogin() {
  const [, setIsLoggedIn] = useRecoilState(isLoggedInState);
  const [, setToken] = useRecoilState(accessTokenState);
  const [error, setError] = useState<boolean>();

  const login = async (username: string, password: string) => {
    try {
      setError(false);
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_PATH}/auth/login`, { username, password });

      localStorage.setItem('access_token', response.data.access_token);
      setToken(response.data.access_token);
      setIsLoggedIn(true);
    } catch (e) {
      setError(true);
      console.log(e);
    }
  };

  return {
    login,
    error,
  };
}
