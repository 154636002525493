import { Col } from 'antd';
import { CustomersSearch } from './CustomersSearch';
import { CustomersTable } from './CustomersTable';


export function CustomersTab() {
  return (
    <Col>
      <CustomersSearch />
      <CustomersTable />
    </Col>
  );
}
