import { useRecoilState } from 'recoil';
import { accessTokenState } from '@atoms/accessTokenState';

export function useAccessToken() {
  const [token, setToken] = useRecoilState(accessTokenState);

  return {
    token,
    setToken,
  };
}
