import React from 'react';
import { Card, Col, Row } from 'antd';
import { LaserCutTable } from '@components/laser-cut/LaserCutTable';


export function Welding() {
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card
          bordered={false}
          className="responsive-card"
        >
          <LaserCutTable />
        </Card>
      </Col>
    </Row>
  );
}
