import { Card, Col, Row, Tabs, type TabsProps } from 'antd';
import { useState } from 'react';
import { CustomersTab } from './components/customers';
import { ReferrersTab } from './components/referrers';
import { SourcesTab } from './components/sources';

enum RegistryTab {
  customers = 'customers',
  referrers = 'referrers',
  sources = 'sources',
}

const items: TabsProps['items'] = [
  {
    label: 'Clienti',
    key: RegistryTab.customers,
    children: <CustomersTab />,
  },
  {
    label: 'Segnalatori',
    key: RegistryTab.referrers,
    children: <ReferrersTab />,
  },
  {
    label: 'Provenienze',
    key: RegistryTab.sources,
    children: <SourcesTab />,

  },
];

export function Registry() {
  const [activeKey, setActiveKey] = useState<string>(RegistryTab.customers);

  return (
    <Row>
      <Col span={24}>
        <Card title="Anagrafiche">
          <Tabs activeKey={activeKey} onChange={setActiveKey} items={items} />
        </Card>
      </Col>
    </Row>
  );
}
