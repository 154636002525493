import React from 'react';
import { Col, Row, Typography } from 'antd';
import ClerksChart from '@components/dashboard/ClerksChart';
import PreventivesChart from '@components/dashboard/PreventivesChart';


export function Dashboard(): JSX.Element {

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        {/*<Typography.Title>Dashboard</Typography.Title>*/}
        <Row>
          <Col span={12}>
            <Typography.Title>Preventivi</Typography.Title>
            <PreventivesChart />
          </Col>
          <Col span={12}>
            <Typography.Title>Commesse</Typography.Title>
            <ClerksChart />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
