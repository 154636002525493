import { useMutation, useQuery } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios from 'axios';
import { Quotation, WorkOrder, WorkOrderStatus } from '@aternum-software/abruzzo-lamiere-common';
import { apiBaseUrl, ApiPath } from '@constants/constants';


async function getWorkOrders(): Promise<WorkOrder[]> {
  const response = await axios.get<WorkOrder[]>(`${apiBaseUrl}/${ApiPath.WorkOrders}`);

  return response.data;
}

async function getWorkOrderById(id: number): Promise<WorkOrder> {
  const response = await axios.get<WorkOrder>(`${apiBaseUrl}/${ApiPath.WorkOrders}/${id}`);

  return response.data;
}

async function createWorkOrder(): Promise<WorkOrder> {
  const response = await axios.post<WorkOrder>(`${apiBaseUrl}/${ApiPath.WorkOrders}`);

  return response.data
}

async function getWorkOrderStatuses(): Promise<WorkOrderStatus[]> {
  const response = await axios.get<WorkOrderStatus[]>(`${apiBaseUrl}/${ApiPath.WorkOrderStatuses}`);

  return response.data;
}

async function getQuotations(): Promise<Quotation[]> {
  const response = await axios.get<Quotation[]>(`${apiBaseUrl}/${ApiPath.WorkOrders}/quotations`);

  return response.data;
}

async function addQuotation({ workOrderId, quotationId }: {workOrderId: number, quotationId: number}): Promise<null> {
  await axios.post(`${apiBaseUrl}/${ApiPath.WorkOrders}/${workOrderId}/quotations`, { quotationId });

  return null;
}

async function addProduct({ workOrderId, productId }: {workOrderId: number, productId: number}): Promise<null> {
  await axios.post(`${apiBaseUrl}/${ApiPath.WorkOrders}/${workOrderId}/products`, { productId });

  return null;
}

export function useGetWorkOrders() {
  return useQuery({
    queryKey: [QueryKey.GetWorkOrders],
    queryFn: getWorkOrders,
  });
}

export function useGetWorkOrderById(id: number) {
  return useQuery({
    queryKey: [QueryKey.GetWorkOrderById, id],
    queryFn: () => getWorkOrderById(id),
    enabled: !!id,
  })
}

export function useCreateWorkOrder() {
  return useMutation({
    mutationKey: [QueryKey.CreateWorkOrder],
    mutationFn: createWorkOrder,
  });
}

export function useGetWorkOrderStatuses() {
  return useQuery({
    queryKey: [QueryKey.GetWorkOrderStatuses],
    queryFn:  getWorkOrderStatuses,
  })
}

export function useGetWorkOrderQuotations() {
  return useQuery({
    queryKey: [QueryKey.GetFreeQuotations],
    queryFn: getQuotations,
  })
}

export function useAddQuotation() {
  return useMutation({
    mutationKey: [QueryKey.WorkOrderAddQuotation],
    mutationFn: addQuotation,
  })
}

export function useAddProduct() {
  return useMutation({
    mutationKey: [QueryKey.WorkOrderAddProduct],
    mutationFn: addProduct,
  })
}
