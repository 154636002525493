import React from 'react';
import { Spin, Table } from 'antd';


export function LaserCutTable() {
  const columns = [
    {
      title: 'Commessa',
      dataIndex: 'description',
      key: 'description',
    },
  ];

  return (
    <Spin spinning={false}>
      <Table
        columns={columns}
        dataSource={[]}
        pagination={{
          pageSizeOptions: ['10', '20', '30', '50'],
          responsive: true,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} elementi`,
          total: 0,
        }}
        rowKey="id"
        size="small"
      />
    </Spin>
  );
}
