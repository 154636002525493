import { useMutation } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios from 'axios';
import { apiBaseUrl, ApiPath } from '@constants/constants';
import { CreateProductRequest, CreateProductResponse } from '@aternum-software/abruzzo-lamiere-common';

async function createProduct(product: CreateProductRequest): Promise<CreateProductResponse> {
  const { data } = await axios.post<CreateProductResponse>(`${apiBaseUrl}/${ApiPath.products}`, product);

  return data
}

export const useCreateProduct = () => useMutation<CreateProductResponse, unknown, CreateProductRequest>({
  mutationFn: createProduct,
  mutationKey: [QueryKey.CreateProduct],
});
