import { useAccessToken } from './useAccessToken';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { isLoggedInState } from '@atoms/isLoggedInState';
import axios from 'axios';

export function useAxiosInterceptor() {
  const localStorageToken = localStorage.getItem('access_token');
  const [, setIsLoggedIn] = useRecoilState(isLoggedInState);
  const { token, setToken } = useAccessToken();
  const [isLoading, setIsLoading] = useState(true);

  const logout = () => {
    localStorage.removeItem('access_token');
    setToken('');
    setIsLoggedIn(false);
  };

  useEffect(() => {
    let reqInterceptor: number;
    let resInterceptor: number;

    if (token) {
      reqInterceptor = axios.interceptors.request.use(
        config => {
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        });
      resInterceptor = axios.interceptors.response.use(
        response => {
          return response;
        },
        error => {
          if (error.response.status === 401) {
            logout();
          }

          return Promise.reject(error);
        });
    }

    return () => {
      axios.interceptors.request.eject(reqInterceptor);
      axios.interceptors.response.eject(resInterceptor);
    };
  }, [token]);

  useEffect(() => {
    if (localStorageToken && !token) {
      setToken(localStorageToken);
      setIsLoggedIn(true);
    }

    if (!localStorageToken) {
      logout();
    }

    setIsLoading(false);
  }, [localStorageToken, token]);

  return {
    isLoading,
  };
}
