import { Breadcrumb, Card, Col, List, Row, Typography } from 'antd';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useGetWorkOrderById } from '@api/work-orders';

export function WorkOrderProduct() {
  const params = useParams();

  const { data: workOrder, isLoading: isLoadingWorkOrder } = useGetWorkOrderById(Number(params?.id) ?? 0);

  const quotation = workOrder?.quotations?.find(i => i.id === Number(params.quotationId));

  const product = quotation?.products.find(i => i.id === Number(params.productId));

  const items = [
    { title: <Link to="/work-orders">Commesse</Link> },
    { title: <Link to={`/work-orders/${params.id}`}>Commessa: {workOrder?.year}-{workOrder?.serial}</Link> },
    { title: `Preventivo: ${quotation?.year}-${quotation?.serial}` },
    { title: `${product?.description}` },
  ];

  console.log('product', product);

  const productData = [
    // @ts-ignore
    'Tipologia: ' + product?.options.find(o => o.key === 'door-type')?.params[0].values?.find(v => v.id === product?.options.find(o => o.key === 'door-type')?.params[0].value?.value).description,
    // @ts-ignore
    'Serratura: ' + product?.options.find(o => o.key === 'door-lock')?.params[0].values?.find(v => v.id === product?.options.find(o => o.key === 'door-lock')?.params[0].value?.value).description,
    // @ts-ignore
    'Luce Porta: ' + product?.options.find(o => o.key === 'door-light')?.params[0].value + ' + ' + product?.options.find(o => o.key === 'little-light')?.params[0].value + ' x ' + product?.options.find(o => o.key === 'door-light')?.params[1].value,

    // @ts-ignore
    'Luce Interno Falso Telaio: ' + product?.options.find(o => o.key === 'false-frame-internal-light')?.params[0].value + ' x ' + product?.options.find(o => o.key === 'false-frame-internal-light')?.params[1].value,

    // @ts-ignore
    'Ingombro Massimo Falso Telaio: ' + product?.options.find(o => o.key === 'false-frame-max-size')?.params[0].value + ' x ' + product?.options.find(o => o.key === 'false-frame-max-size')?.params[1].value,

    // @ts-ignore
    'Verso Apertura: ' + product?.options.find(o => o.key === 'opening')?.params[0].values?.find(v => v.id === product?.options.find(o => o.key === 'opening')?.params[0].value?.value).description,

    // @ts-ignore
    'Senso Apertura: ' + product?.options.find(o => o.key === 'opening')?.params[1].values?.find(v => v.id === product?.options.find(o => o.key === 'opening')?.params[1].value?.value).description,

    // @ts-ignore
    'Serie Cilindro: ' + product?.options.find(o => o.key === 'cylinder')?.params[0].values?.find(v => v.id === product?.options.find(o => o.key === 'cylinder')?.params[0].value?.value).description,

    // @ts-ignore
    'Modello Cilindro: ' + product?.options.find(o => o.key === 'key-knob')?.params[0].values?.find(v => v.id === product?.options.find(o => o.key === 'key-knob')?.params[0].value?.value).description,

    // @ts-ignore
    'Limitatore: ' + product?.options.find(o => o.key === 'limiter')?.params[0].values?.find(v => v.id === product?.options.find(o => o.key === 'limiter')?.params[0].value?.value).description,

    // @ts-ignore
    'Special: ' + product?.options.find(o => o.key === 'corners')?.params[0].values?.find(v => v.id === product?.options.find(o => o.key === 'corners')?.params[0].value?.value).description,

    // @ts-ignore
    'Quantità: ' + product?.quantity,
  ];

  const externalPanelData = [
    // @ts-ignore
    'Misure Anta: ' + (product?.options.find(o => o.key === 'door-light')?.params[0].value + 18) + ' x ' + (product?.options.find(o => o.key === 'door-light')?.params[1].value + 3),
    // @ts-ignore
    'Misure Antina: ' + (product?.options.find(o => o.key === 'little-light')?.params[0].value - 15) + ' x ' + (product?.options.find(o => o.key === 'door-light')?.params[1].value + 3),

    // @ts-ignore
    'Materiale: ' + product?.options.find(o => o.key === 'external-panel')?.params[0].values?.find(v => v.id === product?.options.find(o => o.key === 'external-panel')?.params[0].value?.value).description,
  ];

  const internalPanelData = [
    // @ts-ignore
    'Misure Anta: ' + (product?.options.find(o => o.key === 'door-light')?.params[0].value + 53) + ' x ' + (product?.options.find(o => o.key === 'door-light')?.params[1].value + 20),
    // @ts-ignore
    'Misure Antina: ' + (product?.options.find(o => o.key === 'little-light')?.params[0].value - 15) + ' x ' + (product?.options.find(o => o.key === 'door-light')?.params[1].value + 20),
    // @ts-ignore
    'Materiale: ' + product?.options.find(o => o.key === 'internal-panel')?.params[0].values?.find(v => v.id === product?.options.find(o => o.key === 'internal-panel')?.params[0].value?.value).description,
  ];

  const finishData = [
    // @ts-ignore
    'Finitura Interna: ' + product?.options.find(o => o.key === 'finish')?.params[0].values?.find(v => v.id === product?.options.find(o => o.key === 'finish')?.params[0].value?.value).description,
    // @ts-ignore
    'Finitura Esterna: ' + product?.options.find(o => o.key === 'finish')?.params[1].values?.find(v => v.id === product?.options.find(o => o.key === 'finish')?.params[1].value?.value).description,
    // @ts-ignore
    'Colore Telaio: ' + product?.options.find(o => o.key === 'color')?.params[0].values?.find(v => v.id === product?.options.find(o => o.key === 'color')?.params[0].value?.value).description,
  ];

  const skylightData = [
    // @ts-ignore
    'Tipo: ' + product?.options.find(o => o.key === 'skylight')?.params[0].values?.find(v => v.id === product?.options.find(o => o.key === 'skylight')?.params[0].value?.value).description,
    'Altezza: ',
    'Larghezza: ',
    'Misure: ',
    'Spessore: ',
    'Finitura: ',
    '.',
  ];

  const doorData = [
    'Tipo: ',
    'Altezza: ',
    'Larghezza: ',
    'Altezza Terra: ',
    'Misure: ',
    'Spessore: ',
    'Finitura: ',
  ];

  const littleDoorData = [
    'Tipo: ',
    'Altezza: ',
    'Larghezza: ',
    'Altezza Terra: ',
    'Misure: ',
    'Spessore: ',
    'Finitura: ',
  ];

  const externalPadsData = [
    // @ts-ignore
    'Tipologia: ' + product?.options.find(o => o.key === 'pads')?.params[0].values?.find(v => v.id === product?.options.find(o => o.key === 'pads')?.params[0].value?.value).description,
    'Materiale: ',
    'Profondità: ',
    'Colore :',
  ];

  const externalEscutcheonsData = [
    // @ts-ignore
    'Tipologia: ' + product?.options.find(o => o.key === 'external-escutcheons')?.params[0].values?.find(v => v.id === product?.options.find(o => o.key === 'external-escutcheons')?.params[0].value?.value).description,
    'Materiale: ',
    'Larghezza: ',
    'Colore :',
  ];

  const internalPadsData = [
    // @ts-ignore
    'Tipologia: ' + product?.options.find(o => o.key === 'pads')?.params[0].values?.find(v => v.id === product?.options.find(o => o.key === 'pads')?.params[0].value?.value).description,
    'Materiale: ',
    'Profondità: ',
    'Colore :',
  ];

  const interalEscutcheonsEdata = [
    // @ts-ignore
    'Tipologia: ' + product?.options.find(o => o.key === 'internal-escutcheons')?.params[0].values?.find(v => v.id === product?.options.find(o => o.key === 'internal-escutcheons')?.params[0].value?.value).description,
    'Materiale: ',
    'Larghezza: ',
    'Colore :',
  ];

  const certificationsData = [
    // @ts-ignore
    'Classe Antieffrazione: ' + product?.options.find(o => o.key === 'anti-burglary')?.params[0].values?.find(v => v.id === product?.options.find(o => o.key === 'anti-burglary')?.params[0].value?.value).description,
    // @ts-ignore
    'Classe Termica: ' + product?.options.find(o => o.key === 'thermal-kit')?.params[0].values?.find(v => v.id === product?.options.find(o => o.key === 'thermal-kit')?.params[0].value?.value).description.split(' ')[0],
  ]

  const accessoriesData = [
    '',
  ];

  const servicesData = [
    ''
  ];

  console.log('productData', productData);

  if (isLoadingWorkOrder) {
    return null;
  }

  return (
    <Row>
      <Col span={24}>
        <Card
          title={<Breadcrumb style={{ marginLeft: 8 }} items={items} />}
        >
          <Row gutter={16}>
            <Col span={8}>
              <List
                style={{ marginBottom: 20 }}
                header={<Typography.Title level={5} style={{ margin: 0 }}>{product?.description}</Typography.Title>}
                bordered
                dataSource={productData}
                renderItem={item => (
                  <List.Item>
                    {item}
                  </List.Item>
                )}
              />
            </Col>
            <Col span={8}>
              <Col span={24}>
                <List
                  style={{ marginBottom: 20 }}
                  header={<Typography.Title level={5} style={{ margin: 0 }}>Pannello Esterno</Typography.Title>}
                  bordered
                  dataSource={externalPanelData}
                  renderItem={item => (
                    <List.Item>
                      {item}
                    </List.Item>
                  )}
                />
              </Col>
              <Col span={24}>
                <List
                  style={{ marginBottom: 20 }}
                  header={<Typography.Title level={5} style={{ margin: 0 }}>Finiture</Typography.Title>}
                  bordered
                  dataSource={finishData}
                  renderItem={item => (
                    <List.Item>
                      {item}
                    </List.Item>
                  )}
                />
              </Col>
            </Col>

            <Col span={8}>
              <Col span={24}>
                <List
                  style={{ marginBottom: 20 }}
                  header={<Typography.Title level={5} style={{ margin: 0 }}>Pannello Interno</Typography.Title>}
                  bordered
                  dataSource={internalPanelData}
                  renderItem={item => (
                    <List.Item>
                      {item}
                    </List.Item>
                  )}
                />
              </Col>

              <Col span={24}>
                <List
                  style={{ marginBottom: 20 }}
                  header={<Typography.Title level={5} style={{ margin: 0 }}>Certificazioni</Typography.Title>}
                  bordered
                  dataSource={certificationsData}
                  renderItem={item => (
                    <List.Item>
                      {item}
                    </List.Item>
                  )}
                />
              </Col>
            </Col>

            <Col span={8}>
              <List
                style={{ marginBottom: 20 }}
                header={<Typography.Title level={5} style={{ margin: 0 }}>Vetro Lucernaio</Typography.Title>}
                bordered
                dataSource={skylightData}
                renderItem={item => (
                  <List.Item>
                    {item}
                  </List.Item>
                )}
              />
            </Col>

            <Col span={8}>
              <List
                style={{ marginBottom: 20 }}
                header={<Typography.Title level={5} style={{ margin: 0 }}>Vetro Anta</Typography.Title>}
                bordered
                dataSource={doorData}
                renderItem={item => (
                  <List.Item>
                    {item}
                  </List.Item>
                )}
              />
            </Col>

            <Col span={8}>
              <List
                style={{ marginBottom: 20 }}
                header={<Typography.Title level={5} style={{ margin: 0 }}>Vetro Antina</Typography.Title>}
                bordered
                dataSource={littleDoorData}
                renderItem={item => (
                  <List.Item>
                    {item}
                  </List.Item>
                )}
              />
            </Col>

            <Col span={12}>
              <List
                style={{ marginBottom: 20 }}
                header={<Typography.Title level={5} style={{ margin: 0 }}>Imbotte Esterno</Typography.Title>}
                bordered
                dataSource={externalPadsData}
                renderItem={item => (
                  <List.Item>
                    {item}
                  </List.Item>
                )}
              />
            </Col>

            <Col span={12}>
              <List
                style={{ marginBottom: 20 }}
                header={<Typography.Title level={5} style={{ margin: 0 }}>Mostrine Esterne</Typography.Title>}
                bordered
                dataSource={externalEscutcheonsData}
                renderItem={item => (
                  <List.Item>
                    {item}
                  </List.Item>
                )}
              />
            </Col>

            <Col span={12}>
              <List
                style={{ marginBottom: 20 }}
                header={<Typography.Title level={5} style={{ margin: 0 }}>Imbotte Interno</Typography.Title>}
                bordered
                dataSource={internalPadsData}
                renderItem={item => (
                  <List.Item>
                    {item}
                  </List.Item>
                )}
              />
            </Col>

            <Col span={12}>
              <List
                style={{ marginBottom: 20 }}
                header={<Typography.Title level={5} style={{ margin: 0 }}>Mostrine Interne</Typography.Title>}
                bordered
                dataSource={interalEscutcheonsEdata}
                renderItem={item => (
                  <List.Item>
                    {item}
                  </List.Item>
                )}
              />
            </Col>

            <Col span={12}>
              <List
                style={{ marginBottom: 20 }}
                header={<Typography.Title level={5} style={{ margin: 0 }}>Accessori</Typography.Title>}
                bordered
                dataSource={accessoriesData}
                renderItem={item => (
                  <List.Item>
                    {item}
                  </List.Item>
                )}
              />
            </Col>

            <Col span={12}>
              <List
                style={{ marginBottom: 20 }}
                header={<Typography.Title level={5} style={{ margin: 0 }}>Servizi</Typography.Title>}
                bordered
                dataSource={servicesData}
                renderItem={item => (
                  <List.Item>
                    {item}
                  </List.Item>
                )}
              />
            </Col>

          </Row>
        </Card>
      </Col>
    </Row>
  );
}