import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Layout, Menu, Row, theme } from 'antd';
import { MenuProps } from 'antd/lib';
import {
  DashboardOutlined,
  GroupOutlined,
  LoginOutlined, OrderedListOutlined,
  PrinterOutlined,
  ProductOutlined,
  SettingOutlined,
  ToolOutlined,
} from '@ant-design/icons';
import logo from '../../img/logo.png';
import { useWorkstationType } from '@hooks/useWorkstationType';
import { WorkstationType } from '@constants/WorkstationType';
import { useRecoilState } from 'recoil';
import { userRolesState } from '@atoms/userRolesState';


const { Sider } = Layout;

const dashboardItem = {
  key: 'dashboard',
  label: <Link to="/">Dashboard</Link>,
  icon: React.createElement(DashboardOutlined),
};

const registryItem = {
  key: 'registry',
  label: <Link to="/registry">Anagrafiche</Link>,
  icon: React.createElement(PrinterOutlined),
};

const quotesItem = {
  key: 'quotes',
  label: <Link to="/quotes">Preventivi</Link>,
  icon: React.createElement(PrinterOutlined),
};

const productsItem = {
  key: 'products',
  label: <Link to="/products">Prodotti</Link>,
  icon: React.createElement(ProductOutlined),
};

const priceListItem = {
  key: 'price-list',
  label: <Link to="/price-list">Listini Prezzi</Link>,
  icon: React.createElement(OrderedListOutlined),
};

const workOrdersItem = {
  key: 'work-orders',
  label: <Link to="/work-orders">Commesse</Link>,
  icon: React.createElement(GroupOutlined),
};

const laserCutItem = {
  key: 'laser-cut',
  label: <Link to="/laser-cut">Taglio Laser</Link>,
  icon: React.createElement(ToolOutlined),
};

const weldingItem = {
  key: 'welding',
  label: <Link to="/welding">Saldatura</Link>,
  icon: React.createElement(ToolOutlined),
};

const assemblyItem = {
  key: 'assembly',
  label: <Link to="/assembly">Assemblaggio</Link>,
  icon: React.createElement(ToolOutlined),
};

const settingsItem = {
  key: 'settings',
  label: <Link to="/settings">Settings</Link>,
  icon: React.createElement(SettingOutlined),
};

const logoutItem = {
  key: 'logout',
  label: <Link to="/logout">Logout</Link>,
  icon: React.createElement(LoginOutlined),
};


export const SideNavigation: React.FC = () => {
  const location = useLocation();
  const [userRoles] = useRecoilState(userRolesState);
  const { workstationType } = useWorkstationType();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const items: MenuProps['items'] = useMemo(() => {
    const items = [dashboardItem];

    if (workstationType === WorkstationType.Admin && userRoles.some(r => r.role === 'admin')) {
      items.push(productsItem);
      items.push(priceListItem);
      items.push(quotesItem);
      items.push(workOrdersItem);
    }

    if (userRoles.some(r => ['admin', 'worker'].includes(r.role))) {
      if (workstationType === WorkstationType.Admin || workstationType === WorkstationType.LaserCut) {
        items.push(laserCutItem);
      }

      if (workstationType === WorkstationType.Admin || workstationType === WorkstationType.Welding) {
        items.push(weldingItem);
      }

      if (workstationType === WorkstationType.Admin || workstationType === WorkstationType.Assembly) {
        items.push(assemblyItem);
      }
    }

    if (workstationType === WorkstationType.Admin && userRoles.some(r => r.role === 'admin')) {
      items.push(registryItem);
    }

    items.push(settingsItem);
    items.push(logoutItem);

    return items;
  }, [userRoles, workstationType]);

  const findBestMatchKey = () => {
    const pathSegment = location.pathname.split('/')[1];

    let match = '';

    items.forEach(item => {
      let itemKey = item?.key;

      if (itemKey && itemKey === pathSegment) {
        match = itemKey;
      }
    });

    if (match === '' && items) {
      match = items[0]?.key as string;
    }

    return match;
  };

  const selectedKey = findBestMatchKey();

  return (
    <Sider
      style={{ background: colorBgContainer }}
      breakpoint="lg"
      collapsedWidth="0"
      onBreakpoint={broken => console.log(broken)}
      onCollapse={(collapsed, type) => console.log(collapsed, type)}
    >
      <Row justify="center" style={{ margin: '24px 0' }}>
        <img width={160} src={logo} alt="logo abruzzo lamiere" />
      </Row>

      <Menu mode="inline" defaultSelectedKeys={[selectedKey]} items={items} />
    </Sider>
  );
};
