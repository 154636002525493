import { Button, Card, Col, Divider, Form, Input, Row, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { useGetProduct } from '@api/products';
import { atom } from 'recoil';
import slugify from 'slugify';


export function EditProduct() {
  const params = useParams();
  const [newOptionForm] = Form.useForm();

  const { data: product, isLoading: isLoadingGetProduct } = useGetProduct(params?.id  ?? '');

  const onNewOptionValuesChange = (values: any) => {
    console.log('onNewOptionValuesChange', values);
    newOptionForm.setFieldValue('key', slugify(values.name))
  }

  if (isLoadingGetProduct) {
    return null;
  }

  return (
    <Row>
      <Col span={24}>
        <Card title="Modifica Prodotto">
          <Form layout="vertical">
            <Form.Item name="product-description" label="Descrizione" initialValue={product?.description ?? ''}>
              <Input />
            </Form.Item>
          </Form>

          <Divider />

          <Typography.Title level={3}>Opzioni</Typography.Title>

          <Col span={12}>
            <Card size="small" title="Nuova Optione" extra={<Button type="primary" size="small" ghost>Aggiungi</Button>}>
              <Form form={newOptionForm} name="new-option" layout="vertical" onValuesChange={onNewOptionValuesChange}>
                <Form.Item name="name" label="Nome">
                  <Input />
                </Form.Item>

                <Form.Item name="key" label="Chiave Univoca">
                  <Input disabled />
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Card>
      </Col>
    </Row>
  )
}
