import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { QueryKey } from '@constants/queries';
import { Quotation } from '@aternum-software/abruzzo-lamiere-common';

async function getQuotationById(id: number): Promise<Quotation | null> {
  try {
    const response = await axios.get(`${apiBaseUrl}/quotations/${id}`);

    return response.data;
  } catch (e) {
    return null;
  }
}

export const useGetQuotation = (id: number) => useQuery({
  queryKey: [QueryKey.GetQuotation, id],
  queryFn: () => getQuotationById(id),
});
