import { Button, Card, Col, Row, Space, Table, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useCreateWorkOrder, useGetWorkOrders } from '@api/work-orders';
import { useNavigate } from 'react-router-dom';
import { ColumnProps } from 'antd/es/table';
import { WorkOrder } from '@aternum-software/abruzzo-lamiere-common';
import { getWorkOrderStatusLabel } from './utils';


export function WorkOrders() {
  const navigate = useNavigate();
  const { data: workOrders } = useGetWorkOrders();
  const {
    mutate: createWorkOrder,
    isSuccess: isSuccessCreateWorkOrder,
    data: createWorkOrderData,
  } = useCreateWorkOrder();

  useEffect(() => {
    if (isSuccessCreateWorkOrder) {
      navigate(`/work-orders/${createWorkOrderData.id}`);
    }
  }, [isSuccessCreateWorkOrder, createWorkOrderData?.id]);

  const onNew = () => {
    createWorkOrder();
  };

  const onEdit = (id: number) => {
    navigate(`/work-orders/${id}`);
  };

  const onDelete = (id: number) => {
  };

  const columns: ColumnProps<WorkOrder>[] = [
    {
      title: 'Seriale',
      dataIndex: 'serial',
      key: 'serial',
      render: (_, record) => <Typography>{record.year}-{record.serial}</Typography>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => <Typography>{getWorkOrderStatusLabel(text)}</Typography>,
    },
    {
      title: 'Data',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Azioni Rapide',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => onEdit(record.id)}>Modifica</Button>
          <Button type="link" onClick={() => onDelete(record.id)}>Rimuovi</Button>
        </Space>
      ),
      align: 'right',
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <Card title="Commesse" extra={<Button type="primary" onClick={onNew}>Nuova Commessa</Button>}>
          <Table
            columns={columns}
            dataSource={workOrders}
            pagination={{
              pageSizeOptions: ['10', '20', '30', '50'],
              responsive: true,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} elementi`,
              total: workOrders?.length ?? 0,
            }}
            rowKey="id"
            size="small"
          />
        </Card>
      </Col>
    </Row>
  );
}
