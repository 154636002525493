export enum QueryKey {
  // customers
  GetCustomers = 'GetCustomers',
  GetCustomer = 'GetCustomer',
  UpdateCustomer = 'UpdateCustomer',
  CreateCustomer = 'CreateCustomer',

  // products
  GetProducts = 'GetProducts',
  GetProduct = 'GetProduct',
  CreateProduct = 'CreateProduct',

  // quotations
  GetQuotations = 'GetQuotations',
  GetQuotationReferrals = 'GetQuotationReferrals',
  GetQuotationSources = 'GetQuotationSources',
  GetQuotationStatuses = 'GetQuotationStatuses',
  GetQuotation = 'GetQuotation',
  CreateQuotation = 'CreateQuotation',
  CreateQuotationProduct = 'CreateQuotationProduct',
  UpdateQuotation = 'UpdateQuotation',
  UpdateQuotationProduct = 'UpdateQuotationProduct',
  UpdateQuotationProductParam = 'UpdateQuotationProductParam',

  // list options
  GetListOptionTypes = 'GetListOptionTypes',
  GetListOptions = 'GetListOptions',
  UpdateListOption = 'UpdateListOption',

  // users
  GetProfile = 'GetProfile',
  GetUser = 'GetUser',
  GetUsers = 'GetUsers',
  UpdateUser = 'UpdateUser',

  // auth
  Register = 'Register',

  // work-orders
  GetWorkOrders = 'GetWorkOrders',
  GetWorkOrderStatuses = 'GetWorkOrderStatuses',
  GetWorkOrderById = 'GetWorkOrderById',
  CreateWorkOrder = 'CreateWorkOrder',
  GetFreeQuotations = 'GetFreeQuotations',
  GetFreeProducts = 'GetFreeProducts',
  WorkOrderAddQuotation = 'WorkOrderAddQuotation',
  WorkOrderAddProduct = 'WorkOrderAddProduct',
}
