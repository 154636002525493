import { Button, Card, Col, Form, Input, Modal, Row, Space, Table } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { useGetProducts } from '@api/products';
import { SelectProduct } from '@aternum-software/abruzzo-lamiere-common';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useCreateProduct } from '@api/products/useCreateProduct';


export function Products() {
  const navigate = useNavigate();

  const [newProductForm] = Form.useForm();

  const [isNewProductModalOpen, setIsNewProductModalOpen] = useState(false);

  const { data } = useGetProducts();

  const { mutate: createProduct, data: createProductData, isSuccess: createProductSuccess } = useCreateProduct();

  useEffect(() => {
    if (createProductSuccess) {
      console.log('createProductSuccess', createProductData);
      navigate(`/products/${createProductData.id}`);
    }
  }, [createProductData, createProductSuccess]);

  const onNew = () => {
    setIsNewProductModalOpen(true);
  };

  const onEdit = (id: number) => {
    navigate(`/products/${id}`);
  };

  const onDelete = (id: number) => {
    console.log('delete', id);
  };

  const onOkNewProduct = () => {
    const product = newProductForm.getFieldsValue()
    createProduct(product);
  };

  const onCancelNewProduct = () => {
    setIsNewProductModalOpen(false);
  };

  const columns: ColumnProps<SelectProduct>[] = [
    {
      title: 'Descrizione',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Azioni Rapide',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => onEdit(record.id)}>Modifica</Button>
          <Button type="link" onClick={() => onDelete(record.id)}>Rimuovi</Button>
        </Space>
      ),
      align: 'right',
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <Card
          title="Prodotti"
          extra={<Button type="primary" onClick={onNew}>Nuovo Prodotto</Button>}
        >
          <Table
            columns={columns}
            dataSource={data}
            pagination={{
              pageSizeOptions: ['10', '20', '30', '50'],
              responsive: true,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} elementi`,
              total: data?.length ?? 0,
            }}
            rowKey="id"
            size="small"
          />
        </Card>
      </Col>

      <Modal
        title="Crea Nuovo Prodotto"
        open={isNewProductModalOpen}
        onOk={onOkNewProduct}
        onCancel={onCancelNewProduct}
      >
        <Form form={newProductForm} name="new-product" layout="vertical">
          <Form.Item name="description" label="Descrizione">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </Row>
  );
}
