import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { QueryKey } from '@constants/queries';
import { UpdateUserProfileRequest, UserProfileResponse } from '@aternum-software/abruzzo-lamiere-common';


const fetchProfile = async (): Promise<UserProfileResponse | null> => {
  try {
    const response = await axios.get<UserProfileResponse>(`${process.env.REACT_APP_API_BASE_PATH}/profile`);
    return response.data;
  } catch (error) {
    return null;
  }
};

const fetchUsers = async (): Promise<UserProfileResponse[] | []> => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_PATH}/users`);
    return response.data;
  } catch (error) {
    return [];
  }
};

const fetchUser = async (id: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_PATH}/users/${id}`);
    return response.data;
  } catch (error) {
    return {};
  }
};

export const updateUser = async (user: UpdateUserProfileRequest): Promise<UserProfileResponse | null> => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_API_BASE_PATH}/users`, user);

    return response.data;
  } catch (error) {
    return null;
  }
};

export const deleteUser = async (id: string): Promise<null> => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_PATH}/users/${id}/disable`);

    return response.data;
  } catch (error) {
    return null;
  }
};

export const useGetProfile = (config?: { enabled: boolean }) => useQuery({
  queryKey: [QueryKey.GetProfile],
  queryFn: fetchProfile,
  enabled: config?.enabled ?? true,
});

export const useGetUsers = () => useQuery({
  queryKey: [QueryKey.GetUsers],
  queryFn: fetchUsers,
});

export const useGetUser = (id: string) => useQuery({
  queryKey: [QueryKey.GetUser, id],
  queryFn: () => fetchUser(id),
  enabled: id !== '',
});

export const useUpdateUser = () => useMutation({
  mutationFn: updateUser,
  mutationKey: [QueryKey.UpdateUser],
});
