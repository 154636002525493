import React, { useEffect } from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useAxiosInterceptor } from '@hooks/useAxiosInterceptor';
import { isLoggedInState } from '@atoms/isLoggedInState';
import { MainLayout } from './layouts/MainLayout';
import { BaseLayout } from './layouts/BaseLayout';
import { Assembly, Dashboard, EditUser, LaserCut, Login, Logout, Registry, Settings, Welding } from './routes';
import { Quotations } from './routes/quotations/Quotations';
import { EditQuotation } from './routes/quotations/EditQuotation';
import { useWorkstationType } from '@hooks/useWorkstationType';
import { Products } from './routes/products/Products';
import { WorkOrders } from './routes/work-orders/WorkOrders';
import { PriceList } from './routes/PriceList';
import { Customer } from './routes/registry/Customer';
import { EditProduct } from './routes/products/EditProduct';
import { EditWorkOrder } from './routes/work-orders/EditWorkOrder';
import { WorkOrderProduct } from './routes/work-orders/WorkOrderProduct';
import { useGetProfile } from '@api/UsersApi';
import { useGetUserRolesQuery } from '@api/roles';
import { userRolesState } from '@atoms/userRolesState';


const privateRoutes =
  createBrowserRouter([
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { index: true, element: <Dashboard /> },
        {
          path: 'registry',
          children: [
            { index: true, element: <Registry /> },
            { path: 'customers/:id', element: <Customer /> },
          ],
        },
        {
          path: 'products',
          children: [
            { index: true, element: <Products /> },
            { path: ':id', element: <EditProduct /> },
          ],
        },
        {
          path: 'price-list',
          element: <PriceList />,
        },
        {
          path: 'quotes',
          element: <Quotations />,
        },
        {
          path: 'quotes/:id',
          element: <EditQuotation />,
        },
        {
          path: 'work-orders',
          children: [
            { index: true, element: <WorkOrders /> },
            { path: ':id', element: <EditWorkOrder /> },
            { path: ':id/quotations/:quotationId/product/:productId', element: <WorkOrderProduct /> },
          ],
        },
        {
          path: 'laser-cut',
          element: <LaserCut />,
        },
        {
          path: 'welding',
          element: <Welding />,
        },
        {
          path: 'assembly',
          element: <Assembly />,
        },
        {
          path: 'settings',
          children: [
            { index: true, element: <Settings /> },
            { path: 'users/:id', element: <EditUser /> },
          ],
        },
        {
          path: 'logout',
          element: <Logout />,
        },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
    },
  ]);

const publicRoutes =
  createBrowserRouter([
    {
      path: '/',
      element: <BaseLayout />,
      children: [{ index: true, element: <Login /> }],
    },
    {
      path: '*',
      element: <Navigate to="/" replace />,
    },
  ]);

export function useInitApp(enabled = false) {
  useWorkstationType();

  const profile = useGetProfile({ enabled });
  const roles = useGetUserRolesQuery(profile.data?.id ?? '');

  const setUserRoles = useSetRecoilState(userRolesState);

  console.log('profile', profile.data);
  console.log('roles', roles.data);

  useEffect(() => {
    if (roles.isSuccess && Array.isArray(roles.data)) {
      console.log('setUserRoles', roles.data);
      setUserRoles(roles.data);
    }
  }, [roles]);
}

export const RootRouter = () => {
  const { isLoading } = useAxiosInterceptor();
  const [isLoggedIn] = useRecoilState(isLoggedInState);

  useInitApp(isLoggedIn);

  if (isLoading) {
    return null;
  }

  return (
    <RouterProvider router={isLoggedIn ? privateRoutes : publicRoutes} />
  );
};
