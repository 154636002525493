import { Alert, Button, Flex, Form, Input, Typography } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { useLogin } from '@hooks/useLogin';


export function Login() {
  const { login, error } = useLogin();
  const onFinish = (values: { username: string; password: string }) => {
    console.log('values:', values);
    login(values.username, values.password);
  };

  return (
    <Flex align="start" justify="center" style={{ height: '100vh' }}>
      <Form name="login_form" layout="vertical" onFinish={onFinish} style={{ minWidth: 420 }}>
        <Typography.Title>Login</Typography.Title>
        <Form.Item
          name="username"
          rules={[{ required: true, message: 'Please input your username' }]}
          label="Username"
        >
          <Input
            prefix={<UserOutlined />}
            placeholder="Username"
          />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your Password!' }]}
          label="Password"
        >
          <Input
            prefix={<LockOutlined />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>

        <Flex>
          <Alert
            message="Invalid username or password"
            type="error"
            style={{ visibility: error ? 'visible' : 'hidden', margin: 0 }}
            showIcon
          />
        </Flex>

        <Flex style={{ marginTop: 24 }}>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Log in
          </Button>
        </Flex>
      </Form>
    </Flex>
  );
}
