import { Col } from 'antd';
import { SourcesTable } from './SourcesTable';

export function SourcesTab() {
  return (
    <Col>
      <SourcesTable />
    </Col>
  );
}
