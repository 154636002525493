import { useMutation } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { CreateQuotationProductRequest } from '@aternum-software/abruzzo-lamiere-common';

function createQuotation(payload: CreateQuotationProductRequest) {
  return axios.post(`${apiBaseUrl}/quotation-products`, payload);
}

export const useCreateQuotationProduct = () => useMutation({
  mutationFn: createQuotation,
  mutationKey: [QueryKey.CreateQuotationProduct],
});
