import { Breadcrumb, Button, Card, Col, Flex, Form, List, Modal, Row, Select, Typography } from 'antd';
import {
  useAddProduct,
  useAddQuotation,
  useGetWorkOrderById,
  useGetWorkOrderQuotations,
  useGetWorkOrderStatuses,
} from '@api/work-orders';
import { Link, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { BaseSelect } from '@components/form/BaseSelect';
import { getWorkOrderStatusLabel } from './utils';
import { useGetProducts } from '@api/products';


export function EditWorkOrder() {
  const params = useParams();

  const [addQuotationId, setAddQuotationId] = useState<number | null>(null);
  const [addProductId, setAddProductId] = useState<number | null>(null);

  const [isAddQuotationModalOpen, setIsAddQuotationModalOpen] = useState(false);
  const [isAddProductModalOpen, setIsAddProductModalOpen] = useState(false);

  const { data: workOrder, isLoading: isLoadingWorkOrder, refetch: refetchWorkOrder } = useGetWorkOrderById(Number(params?.id) ?? 0);
  const { data: workOrderStatuses, isLoading: isLoadingWorkOrderStatuses } = useGetWorkOrderStatuses();

  const { mutate: addQuotation, isSuccess: isSuccessAddQuotation } = useAddQuotation();
  const { mutate: addProduct, isSuccess: isSuccessAddProduct } = useAddProduct();

  const { data: quotations, refetch: refetchQuotations } = useGetWorkOrderQuotations();
  const { data: products } = useGetProducts();

  const statusesOptions = workOrderStatuses?.map(s => ({ key: s, value: getWorkOrderStatusLabel(s) })) ?? [];

  console.log('workOrderStatuses', workOrderStatuses);

  const items = [
    { title: <Link to="/work-orders">Commesse</Link> },
    { title: `Commessa ${workOrder?.year}-${workOrder?.serial}` },
  ];

  const onValuesChange = (values: any) => {
    console.log('onValuesChange', values);
  };

  const onOkAddQuotation = () => {
    if (params.id && addQuotationId) {
      addQuotation({ workOrderId: Number(params.id), quotationId: addQuotationId });

      setAddQuotationId(null);
    }
  };

  const onCancelAddQuotation = () => {
    setIsAddQuotationModalOpen(false);
    setAddQuotationId(null);
  };

  const onOkAddProduct = () => {
    if (params.id && addProductId) {
      addProduct({ workOrderId: Number(params.id), productId: addProductId });

      setAddProductId(null);
    }
  };

  const onCancelAddProduct = () => {
    setIsAddProductModalOpen(false);
    setAddProductId(null);
  };

  useEffect(() => {
    if (isSuccessAddQuotation) {
      refetchQuotations();
      refetchWorkOrder();
      setIsAddQuotationModalOpen(false);
    }
  }, [isSuccessAddQuotation]);

  useEffect(() => {
    if (isSuccessAddProduct) {
      setIsAddProductModalOpen(false);
      refetchWorkOrder();
    }
  }, [isSuccessAddProduct]);

  if (isLoadingWorkOrder || isLoadingWorkOrderStatuses) {
    return null;
  }

  return (
    <Row>
      <Col span={24}>
        <Card
          title={<Breadcrumb style={{ marginLeft: 8 }} items={items} />}
          extra={
            <Flex gap="small" align="flex-start" vertical>
              <Flex gap="small" wrap>
                <Button type="primary" ghost onClick={() => setIsAddQuotationModalOpen(true)}>Aggiungi
                  Preventivo</Button>
                <Button type="primary" ghost onClick={() => setIsAddProductModalOpen(true)}>Aggiungi Prodotto</Button>
              </Flex>
            </Flex>
          }
        >
          <Row>
            <Form
              name="edit-quotatation"
              onValuesChange={onValuesChange}
              wrapperCol={{ span: 12 }}
              labelCol={{ span: 12 }}
              layout="vertical"
              style={{ width: '50%' }}
            >
              <Form.Item
                name="status"
                label="Stato"
                initialValue={workOrder?.status ?? 'draft'}
                style={{ marginBottom: '0px' }}
              >
                <BaseSelect name="status" options={statusesOptions} />
              </Form.Item>
            </Form>
          </Row>

          <Typography.Title>Preventivi</Typography.Title>
          {workOrder?.quotations?.map(q => (
            <Card title={`Preventivo: ${q.year}-${q.serial}`} style={{marginBottom: 20}}>
              <Row>
                <Col span={12}>
                  <Typography.Title level={5}>Anagrafica</Typography.Title>
                  <Typography>Cliente: {`${q.customer?.firstName ?? ''} ${q.customer?.lastName ?? ''} ${q.customer?.name ?? ''}`}</Typography>
                  <Typography>Telefono: {q.customer?.phoneNumber} </Typography>
                  <Typography>Email: {q.customer?.email}</Typography>
                  <Typography>Indirizzo: {q?.address} {q?.city} {q?.province} {q?.postalCode} {q?.country}</Typography>
                  <Typography>Riferimento: {q?.reference}</Typography>
                  <Typography>Segnalatore: {q.referrer?.description}</Typography>
                </Col>

                <Col>
                  <Typography.Title level={5}>Prodotti</Typography.Title>

                  <List
                    dataSource={q.products}
                    renderItem={item => (
                      <List.Item style={{ borderBottomWidth: 0 }}>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                          <Link to={`/work-orders/${params.id}/quotations/${q.id}/product/${item.id}`}>
                            {item.quantity} x {item.description}
                          </Link>
                        </Typography.Title>
                      </List.Item>
                    )}
                  />
                </Col>
              </Row>
            </Card>
          ))}

          {workOrder?.products?.map(p => (
            <Card title={`Prodotto: ${p.description}`}>

            </Card>
          ))}
        </Card>
      </Col>

      <Modal
        title="Aggiungi Preventivo"
        open={isAddQuotationModalOpen}
        onOk={onOkAddQuotation}
        onCancel={onCancelAddQuotation}
      >
        <Typography>Seleziona il preventivo da aggiungere alla commessa</Typography>
        <Select
          options={quotations?.map(q => ({
            label: `${q.year}-${q.serial} ${q.customer?.firstName ?? ''} ${q.customer?.lastName ?? ''} ${q.customer?.name ?? ''}`,
            value: q.id,
          }))}
          value={addQuotationId}
          onSelect={id => setAddQuotationId(id)}
          style={{ width: '100%' }}
        />
      </Modal>

      <Modal
        title="Aggiungi Prodotto"
        open={isAddProductModalOpen}
        onOk={onOkAddProduct}
        onCancel={onCancelAddProduct}
      >
        <Typography>Seleziona il prodotto da aggiungere alla commessa</Typography>
        <Select
          options={products?.map(q => ({
            label: q.description,
            value: q.id,
          }))}
          value={addProductId}
          onSelect={id => setAddProductId(id)}
          style={{ width: '100%' }}
        />
      </Modal>
    </Row>
  );
}
