import React from 'react';
import { RecoilRoot } from 'recoil';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider, theme } from 'antd';
import { RootRouter } from './RootRouter';
import './App.css';


const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <ConfigProvider
          theme={{
            algorithm: [theme.defaultAlgorithm],
          }}
        >
          <RootRouter />
        </ConfigProvider>
      </RecoilRoot>
    </QueryClientProvider>
  );
}

export default App;
