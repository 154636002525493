import { useMutation } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import { CreateUserRequest, CreateUserResponse } from '@aternum-software/abruzzo-lamiere-common';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { apiBaseUrl, ApiPath } from '@constants/constants';


async function register(user: CreateUserRequest): Promise<AxiosResponse<CreateUserResponse>> {
  return axios.post<CreateUserRequest, AxiosResponse<CreateUserResponse>>(`${apiBaseUrl}/${ApiPath.auth}/register`, user)
}

export function useRegister() {
  return useMutation<AxiosResponse<CreateUserResponse>, AxiosError<{ message: string[] }>, CreateUserRequest>({
    mutationKey: [QueryKey.Register],
    mutationFn: register,
  });
}
