import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { isLoggedInState } from '@atoms/isLoggedInState';
import { accessTokenState } from '@atoms/accessTokenState';


export function Logout() {
  const [, setIsLoggedIn] = useRecoilState(isLoggedInState);
  const [, setAccessToken] = useRecoilState(accessTokenState);

  useEffect(() => {
    localStorage.removeItem('access_token');
    setAccessToken('');
    setIsLoggedIn(false);
  }, []);

  return null;
}