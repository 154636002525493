import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';
import axios from 'axios';
import { apiBaseUrl } from '@constants/constants';
import { SelectProduct } from '@aternum-software/abruzzo-lamiere-common';


async function getProducts(): Promise<SelectProduct[]> {
  try {
    let response = await axios.get<SelectProduct[]>(`${apiBaseUrl}/products`);

    return response.data || [];
  } catch (e) {
    return [];
  }
}

export function useGetProducts() {
  return useQuery({
    queryKey: [QueryKey.GetProducts],
    queryFn: getProducts,
  });
}
