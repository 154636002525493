import React from 'react';
import { Button, Space, Spin, Table } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { GetQuotationReferrerResponse } from '@aternum-software/abruzzo-lamiere-common';
import { useGetQuotationReferrers } from '@api/quotations';


export function ReferrersTable() {
  const { data, isLoading } = useGetQuotationReferrers();

  console.log('referrers data', data);

  const onEdit = (id: number) => {
    console.log('onEdit', id);
  };

  const onDelete = (id: number) => {
    console.log('onDelete', id);
  };

  console.log('customers', data);

  const columns: ColumnProps<GetQuotationReferrerResponse>[] = [
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Azioni Rapide',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => onEdit(record.id)}>Modifica</Button>
          <Button type="link" onClick={() => onDelete(record.id)}>Rimuovi</Button>
        </Space>
      ),
      align: 'right',
    },
  ];

  return (
    <Spin spinning={isLoading}>
      <Table
        columns={columns}
        dataSource={data}
        pagination={{
          pageSizeOptions: ['10', '20', '30', '50'],
          responsive: true,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]}-${range[1]} di ${total} elementi`,
          total: data?.length ?? 0,
        }}
        rowKey="id"
        size="small"
      />
    </Spin>
  );
}
