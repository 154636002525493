import axios from 'axios';
import { Customer } from '@aternum-software/abruzzo-lamiere-common';
import { apiBaseUrl, ApiPath } from '@constants/constants';
import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '@constants/queries';

export const fetchCustomers = async (): Promise<Customer[]> => {
  try {
    const response = await axios.get<Customer[]>(`${apiBaseUrl}/${ApiPath.customers}`);

    return response.data || [];
  } catch (error) {
    console.error('[get-customers]', error);
    return [];
  }
};

export function useGetCustomers() {
  return useQuery({
    queryKey: [QueryKey.GetCustomers],
    queryFn: fetchCustomers,
  });
}
