import { Form, Select } from 'antd';
import React from 'react';

export interface SelectOption {
  key: string | number;
  value: string;
}

export interface BaseSelectProps {
  name: string;
  label?: string;
  defaultValue?: string;
  options: SelectOption[];
  onSelect?: (value: string) => void;
}

export function BaseSelect({ name, label, options, onSelect, defaultValue }: BaseSelectProps) {
  return (
    <Form.Item name={name} label={label}>
      <Select onSelect={onSelect} defaultValue={defaultValue}>
        {options.map((option) => (
          <Select.Option key={option.key} value={option.key}>
            {option.value}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}
