import { useEffect, useState } from 'react';
import { Button, Card, Checkbox, Col, Divider, Form, Input, notification, Row, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import { useGetUser, useUpdateUser } from '@api/UsersApi';
import { useGetRolesQuery, useGetUserRolesQuery, useUpdateUserRoles } from '@api/roles';


export function EditUser() {
  const params = useParams();
  const [api, contextHolder] = notification.useNotification();

  const openNotification = () => {
    api.info({
      message: `Dati aggiornati con successo`,
      placement: 'topRight',
    });
  };

  const [showForm, setShowForm] = useState(false);
  const [roles, setRoles] = useState<string[]>([]);
  const [userRoles, setUserRoles] = useState<string[]>([]);

  const [initialValues, setInitialValues] = useState({});

  const { data, isFetched, refetch: refetchUser } = useGetUser(params?.id ?? '');
  const { data: rolesData, isFetched: isFetchedRolesData } = useGetRolesQuery();
  const {
    data: userRolesData,
    isFetched: isFetchedUserRolesData,
    refetch: refetchUserRolesData,
  } = useGetUserRolesQuery(params?.id ?? '');

  const { mutate, isSuccess: isSuccessUpdateUser, reset: resetUpdateUser } = useUpdateUser();

  const {
    mutate: mutateUserRoles,
    isSuccess: isSuccessUpdateUserRoles,
    reset: resetUpdateUserRoles,
  } = useUpdateUserRoles();

  useEffect(() => {
    if (isSuccessUpdateUserRoles) {
      refetchUserRolesData().then(() => {
        resetUpdateUserRoles();
      });
    }

    if (isSuccessUpdateUser) {
      refetchUser().then(() => {
        resetUpdateUser();
      });
    }

    if (isSuccessUpdateUser && isSuccessUpdateUserRoles) {
      openNotification();
    }
  }, [isSuccessUpdateUser, isSuccessUpdateUserRoles, openNotification, refetchUser, refetchUserRolesData, resetUpdateUser, resetUpdateUserRoles]);

  useEffect(() => {
    if (isFetched && isFetchedUserRolesData && isFetchedRolesData) {
      setInitialValues(data);
      setUserRoles(userRolesData.map((role: any) => role.role));
      setRoles(rolesData);
      setShowForm(true);
    }
  }, [isFetched, isFetchedRolesData, isFetchedUserRolesData]);

  const onFinish = (values: any) => {
    console.log('save', values);

    if (params.id) {
      mutate({ id: params.id, ...values });
      mutateUserRoles({ id: params.id, roles: userRoles });
    }
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card
          bordered={false}
          className="responsive-card"
          title="Edit User"
          extra={
            <>
              <Divider type="vertical" />
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                form="edit-user"
              >Salva</Button>
            </>}
        >
          {showForm && <Form
            name="edit-user"
            layout="vertical"
            onFinish={onFinish}
            style={{ minWidth: 420 }}
            initialValues={initialValues}
          >
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item
                  name="username"
                  rules={[{ required: true, message: 'Per favore inserisci un username valido' }]}
                  label="Username"
                >
                  <Input
                    prefix={<UserOutlined />}
                    placeholder="Username"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={8}>
                <Form.Item
                  name="name"
                  label="Nome"
                >
                  <Input
                    prefix={<UserOutlined />}
                    placeholder="Mario"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="surname"
                  label="Cognome"
                >
                  <Input
                    prefix={<UserOutlined />}
                    placeholder="Rossi"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={8}>
                <Form.Item
                  name="email"
                  label="Email"
                >
                  <Input
                    prefix={<UserOutlined />}
                    placeholder=""
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="phone"
                  label="Telefono"
                >
                  <Input
                    prefix={<UserOutlined />}
                    placeholder=""
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col>
                <Typography.Title level={3}>Roles</Typography.Title>
                <Checkbox.Group options={roles} defaultValue={userRoles} onChange={(values) => setUserRoles(values)} />
              </Col>
            </Row>
          </Form>}
        </Card>
      </Col>
      {contextHolder}
    </Row>
  );
}
