import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Flex,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useGetQuotation } from '@api/quotations/useGetQuotation';
import { useGetCustomers } from '@api/customers';
import { BaseSelect } from '@components/form/BaseSelect';
import { useGetQuotationStatuses } from '@api/quotations/useGetQuotationStatuses';
import { useGetQuotationReferrers } from '@api/quotations';
import { useGetQuotationSources } from '@api/quotations/useGetQuotationSources';
import dayjs from 'dayjs';
import { getQuotationStatusLabel } from './utils';
import { useGetProducts } from '@api/products/useGetProducts';
import { useCreateQuotationProduct } from '@api/quotations/useCreateQuotationProduct';
import { QuotationProduct } from './QuotationProduct';
import { useForm } from 'antd/es/form/Form';
import { useUpdateQuotation } from '@api/quotations/useUpdateQuotation';
import { UpdateQuotationRequest } from '@aternum-software/abruzzo-lamiere-common';
import { useDebouncedCallback } from 'use-debounce';


const ivaOptions = [
  { value: null, label: 'Custom' },
  { value: '0', label: '0%' },
  { value: '0.04', label: '4%' },
  { value: '0.11', label: '11%' },
  { value: '0.22', label: '22%' },
];

const baseDiscountRange = Array.from({ length: 11 }, (_, index) => index * 5);
const discountRange = Array.from({ length: 20 }, (_, index) => index * 5);
const depositRange = Array.from({ length: 21 }, (_, index) => index * 5);

const baseDiscountOptions = [
  { value: null, label: 'Custom' },
  ...baseDiscountRange.map(r => ({ value: String((r / 100).toFixed(2)), label: `${r}%` })),
];

const discountOptions = [
  { value: null, label: 'Custom' },
  ...discountRange.map(r => ({ value: String((r / 100).toFixed(2)), label: `${r}%` })),
];

const depositOptions = [
  { value: null, label: 'Custom' },
  ...depositRange.map(r => ({ value: String((r / 100).toFixed(2)), label: `${r}%` })),
];


export function EditQuotation() {
  const params = useParams();
  const [form] = useForm();

  const [isLoading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<number>(1);

  const [baseDiscount, setBaseDiscount] = useState<string>('0');
  const [discount, setDiscount] = useState<string>('0');
  const [deposit, setDeposit] = useState<string>('0');
  const [iva, setIva] = useState<string>('0');

  const [isBaseDiscountDisabled, setIsBaseDiscountDisabled] = useState(true);
  const [isDiscountDisabled, setIsDiscountDisabled] = useState(true);
  const [isDepositDisabled, setIsDepositDisabled] = useState(true);
  const [isIvaDisabled, setIsIvaDisabled] = useState(true);

  const {
    data: quotation,
    isFetched: isFetchedQuotation,
    refetch: refetchQuotation,
  } = useGetQuotation(Number(params.id));

  const { data: products, isFetched: isFetchedProducts } = useGetProducts();
  const { data: statuses, isFetched: isFetchedStatuses } = useGetQuotationStatuses();
  const { data: customers, isFetched: isFetchedCustomers } = useGetCustomers();
  const { data: referrers, isFetched: isFetchedReferrers } = useGetQuotationReferrers();
  const { data: sources, isFetched: isFetchedSources } = useGetQuotationSources();

  const { mutate: createQuotationProduct, isSuccess: isSuccessQuotationProduct } = useCreateQuotationProduct();
  const {
    mutate: updateQuotation,
    isSuccess: isSuccessUpdateQuotation,
    reset: resetUpdateQuotation,
  } = useUpdateQuotation();

  const debouncedUpdateQuotation = useDebouncedCallback((quotation: Partial<UpdateQuotationRequest>) => updateQuotation(quotation), 1000);

  const items = [
    { title: <Link to="/quotes">Preventivi</Link> },
    { title: `Preventivo ${quotation?.year}-${quotation?.serial}` },
  ];

  const customersOptions = [
    { key: '', value: 'Nessuno' },
    ...customers?.map(c => ({ key: c.id, value: `${c.name} ${c.firstName} ${c.lastName}` })) ?? [],
  ];

  const statusesOptions = statuses?.map(s => ({ key: s, value: getQuotationStatusLabel(s) })) ?? [];

  const referrersOptions = [
    { key: '', value: 'Nessuno' },
    ...referrers?.map(r => ({ key: r.id, value: r.description })) ?? [],
  ];

  const sourcesOptions = [
    { key: '', value: 'Nessuno' },
    ...sources?.map(r => ({ key: r.id, value: r.description })) ?? [],
  ];

  const productItems = quotation?.products.map(p => ({
    key: p.id,
    label: p.description,
    children: <QuotationProduct product={p} refetch={refetchQuotation} />,
    extra:
      <Row>
        <Col>
          <Typography>Prezzo Unitario € {p.unitPrice}</Typography>
          <Typography>Prezzo Totale € {p.totalPrice}</Typography>
        </Col>
      </Row>,
  })) ?? [];

  const productOptions = products?.map(p => ({ key: p.id, value: p.description })) ?? [];

  const isFetched =
    isFetchedQuotation &&
    isFetchedStatuses &&
    isFetchedCustomers &&
    isFetchedReferrers &&
    isFetchedSources &&
    isFetchedProducts;

  useEffect(() => {
    if (isLoading && isFetched) {
      setLoading(false);
    }
  }, [isFetched, isLoading]);

  useEffect(() => {
    if (quotation?.baseDiscount === null) {
      setIsBaseDiscountDisabled(false);
    } else {
      setIsBaseDiscountDisabled(true);
    }
  }, [quotation?.baseDiscount]);

  useEffect(() => {
    if (quotation?.discount === null) {
      setIsDiscountDisabled(false);
    } else {
      setIsDiscountDisabled(true);
    }
  }, [quotation?.discount]);

  useEffect(() => {
    if (quotation?.deposit === null) {
      setIsDepositDisabled(false);
    } else {
      setIsDepositDisabled(true);
    }
  }, [quotation?.deposit]);

  useEffect(() => {
    if (quotation?.ivaPercentage === null) {
      setIsIvaDisabled(false);
    } else {
      setIsIvaDisabled(true);
    }
  }, [quotation?.ivaPercentage]);

  const onBaseDiscountSelect = (value: string | null) => {
    updateQuotation({
      id: Number(params.id),
      baseDiscount: value,
    });
  };

  useEffect(() => {
    setBaseDiscount(quotation?.baseDiscountAmount ?? '0');
    setDiscount(quotation?.discountAmount ?? '0');
    setDeposit(quotation?.depositAmount ?? '0');
    setIva(quotation?.ivaAmount ?? '0');
  }, [quotation]);

  const onBaseDiscountChange = (e: any) => {
    setBaseDiscount(e.target.value);
    debouncedUpdateQuotation({
      id: Number(params.id),
      baseDiscountAmount: e.target.value,
    });
  };

  const onDiscountSelect = (value: string | null) => {
    updateQuotation({
      id: Number(params.id),
      discount: value,
    });
  };

  const onDiscountChange = (e: any) => {
    setDiscount(e.target.value);
    debouncedUpdateQuotation({
      id: Number(params.id),
      discountAmount: e.target.value,
    });
  };

  const onDepositSelect = (value: string | null) => {
    updateQuotation({
      id: Number(params.id),
      deposit: value,
    });
  };

  const onDepositChange = (e: any) => {
    setDeposit(e.target.value);
    debouncedUpdateQuotation({
      id: Number(params.id),
      depositAmount: e.target.value,
    });
  };

  const onIvaSelect = (value: string | null) => {
    if (value === null) {
      setIsIvaDisabled(false);
    } else {
      setIsIvaDisabled(true);
    }

    updateQuotation({
      id: Number(params.id),
      ivaPercentage: value,
    });

    save({ ivaPercentage: value });
  };

  const onIvaChange = (e: any) => {
    setIva(e.target.value);
    debouncedUpdateQuotation({
      id: Number(params.id),
      ivaAmount: e.target.value,
    });
  };

  const save = (values: any) => {
    const payload: Partial<UpdateQuotationRequest> = { id: Number(params.id) };

    console.log(values);

    if (values.status !== '') {
      payload.status = values.status;
    }

    if (values.customerId !== '') {
      payload.customerId = values.customerId;
    } else {
      payload.customerId = null;
    }

    if (values.referrerId !== '') {
      payload.referrerId = values.referrerId;
    }

    if (values.sourceId !== '') {
      payload.sourceId = values.sourceId;
    }

    payload.reference = values.reference;
    payload.address = values.address;
    payload.city = values.city;
    payload.province = values.province;
    payload.postalCode = values.postalCode;
    payload.country = values.country;

    debouncedUpdateQuotation(payload);
  };

  const onAddProduct = () => {
    createQuotationProduct({
      quotationId: Number(params.id),
      productId: selectedProduct,
    });
  };

  const onSelect = () => {
    form.submit();
  };

  useEffect(() => {
    if (isSuccessQuotationProduct) {
      setIsModalOpen(false);
      refetchQuotation();
    }
  }, [isSuccessQuotationProduct]);

  useEffect(() => {
    if (isSuccessUpdateQuotation) {
      refetchQuotation().then((quotation) => {
        form.setFieldValue('address', quotation.data?.address ?? '');
        form.setFieldValue('city', quotation.data?.city ?? '');
        form.setFieldValue('province', quotation.data?.province ?? '');
        form.setFieldValue('postalCode', quotation.data?.postalCode ?? '');
        form.setFieldValue('country', quotation.data?.country ?? '');
      });
      resetUpdateQuotation();
    }
  }, [isSuccessUpdateQuotation]);

  const onCloseAddProduct = () => {
    setIsModalOpen(false);
  };

  if (isLoading) {
    return null;
  }

  return (
    <Row>
      <Col span={24}>
        <Card
          title={<Breadcrumb style={{ marginLeft: 8 }} items={items} />}
          extra={
            <Flex gap="small" wrap>
              <Button
                type="primary" ghost onClick={() => setIsModalOpen(true)}
              >Aggiungi Prodotto</Button>
            </Flex>
          }
        >
          <Row gutter={16}>
            <Col span={16}>
              <Form
                name="edit-quotatation"
                form={form}
                onFinish={save}
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
                layout="vertical"
              >
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="status"
                      label="Stato"
                      initialValue={quotation?.status ?? 'draft'}
                      style={{ marginBottom: '0px' }}
                    >
                      <BaseSelect name="status" options={statusesOptions} onSelect={onSelect} />
                    </Form.Item>

                    <Form.Item
                      name="referrerId"
                      label="Segnalatore"
                      initialValue={quotation?.referrerId ?? ''}
                      style={{ marginBottom: '0px' }}
                    >
                      <BaseSelect name="referrerId" options={referrersOptions} onSelect={onSelect} />
                    </Form.Item>

                    <Form.Item
                      name="reference"
                      label="Riferimento"
                      initialValue={quotation?.reference ?? ''}
                    >
                      <Input onChange={onSelect} />
                    </Form.Item>

                    <Form.Item
                      name="address"
                      label="Indirizzo"
                      initialValue={quotation?.address ?? ''}
                    >
                      <Input onChange={onSelect} />
                    </Form.Item>

                    <Form.Item
                      name="province"
                      label="Provincia"
                      initialValue={quotation?.province ?? ''}
                    >
                      <Input onChange={onSelect} />
                    </Form.Item>

                    <Form.Item
                      name="country"
                      label="Nazione"
                      initialValue={quotation?.country ?? ''}
                    >
                      <Input onChange={onSelect} />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="customerId"
                      label="Cliente"
                      initialValue={quotation?.customerId ?? ''}
                      style={{ marginBottom: '0px' }}
                    >
                      <BaseSelect name="customerId" options={customersOptions} onSelect={onSelect} />
                    </Form.Item>

                    <Form.Item
                      name="sourceId"
                      label="Provenienza"
                      initialValue={quotation?.sourceId ?? ''}
                      style={{ marginBottom: '0px' }}
                    >
                      <BaseSelect name="sourceId" options={sourcesOptions} onSelect={onSelect} />
                    </Form.Item>

                    <Form.Item
                      name="createdAt"
                      label="Data di Creazione"
                      initialValue={dayjs(quotation?.createdAt)}
                    >
                      <DatePicker
                        style={{ width: '100%' }}
                        format={'DD/MM/YYYY'}
                        disabled={true}
                      />
                    </Form.Item>

                    <Form.Item
                      name="city"
                      label="Città"
                      initialValue={quotation?.city ?? ''}
                    >
                      <Input onChange={onSelect} />
                    </Form.Item>

                    <Form.Item
                      name="postalCode"
                      label="CAP"
                      initialValue={quotation?.postalCode ?? ''}
                    >
                      <Input onChange={onSelect} />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>

            <Col span={8}>
              <Space direction="vertical">
                <Input
                  className="readable-disabled-input"
                  addonBefore="Sconto Base"
                  addonAfter={
                    <Select
                      defaultValue={quotation?.baseDiscount}
                      options={baseDiscountOptions}
                      onSelect={onBaseDiscountSelect}
                    />
                  }
                  disabled={isBaseDiscountDisabled}
                  value={baseDiscount}
                  onChange={onBaseDiscountChange}
                />

                <Input
                  className="readable-disabled-input"
                  addonBefore="Sconto"
                  addonAfter={
                    <Select
                      defaultValue={quotation?.discount}
                      options={discountOptions}
                      onSelect={onDiscountSelect}
                    />
                  }
                  disabled={isDiscountDisabled}
                  value={discount}
                  onChange={onDiscountChange}
                />

                <Input
                  className="readable-disabled-input"
                  addonBefore="IVA"
                  addonAfter={
                    <Select
                      defaultValue={quotation?.ivaPercentage}
                      options={ivaOptions}
                      onSelect={onIvaSelect}
                    />
                  }
                  disabled={isIvaDisabled}
                  value={iva}
                  onChange={onIvaChange}
                />

                <Input
                  className="readable-disabled-input"
                  addonBefore="Acconto"
                  addonAfter={
                    <Select
                      defaultValue={quotation?.deposit}
                      options={depositOptions}
                      onSelect={onDepositSelect}
                    />
                  }
                  disabled={isDepositDisabled}
                  value={deposit}
                  onChange={onDepositChange}
                />

                <Divider />

                <Input
                  className="readable-disabled-input"
                  addonBefore="Prezzo Totale"
                  addonAfter="€"
                  disabled
                  value={quotation?.rawTotal ?? 0}
                />

                <Input
                  className="readable-disabled-input"
                  addonBefore="Prezzo Scontato"
                  addonAfter="€"
                  disabled
                  value={quotation?.discountTotal ?? 0}
                />

                <Input
                  className="readable-disabled-input"
                  addonBefore="Prezzo Finale"
                  addonAfter="€"
                  disabled
                  value={quotation?.finalTotal ?? 0}
                />
              </Space>
            </Col>
          </Row>
        </Card>
      </Col>

      <Divider />

      {productItems.length > 0 && (
        <Col span={24} style={{ marginBottom: '100px' }}>
          <Collapse
            size="large"
            items={productItems}
          />
        </Col>
      )}

      <Modal title="Aggiungi Prodotto" open={isModalOpen} onOk={onAddProduct} onCancel={onCloseAddProduct}>
        <Form id="new-product" layout="vertical">
          <Form.Item
            name="product"
            label="Seleziona il tipo di prodotto che vuoi aggiungere al preventivo"
            initialValue={productOptions[0].key}
          >
            <Select onSelect={v => setSelectedProduct(v)} style={{ width: '100%' }}>
              {productOptions.map((option) => (
                <Select.Option key={option.key} value={option.key}>
                  {option.value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </Row>
  );
}
